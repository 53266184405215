<template>
  <div>
    <body-with-header ads title="پشتیبانی">
      <CRow class="h-100">
        <vue-element-loading
          :active="loading"
          :text="loadingText"
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <CCol md="12">
          <CTabs fade variant="tabs">
            <!-- Tab 1: تیکت ها(Ticke) -->
            <CTab active>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span>تیکت ها</span>
                  <font-awesome-icon icon="history" />
                </div>
              </template>
              <tiketForm />
            </CTab>

            <!-- Tab 2: راهنمای سامانه (Specialists) -->
            <CTab>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span>راهنمای سامانه</span>
                  <font-awesome-icon icon="user-md" />
                </div>
              </template>
              <helpMoveForm />
            </CTab>

            <!-- Tab 3:  سوالات متداول (Farm Employees) -->
            <CTab>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span>سوالات متداول</span>
                  <font-awesome-icon icon="users" />
                </div>
              </template>
              <helpQuestionForm />
            </CTab>
          </CTabs>
        </CCol>
      </CRow>
    </body-with-header>
  </div>
</template>
  <script>
import BodyWithHeader from "../../components/bodyWithHeader.vue";
import helpQuestionForm from "./helpQuestion/helpQuestionForm.vue";
import tiketForm from "./tiket/tiketForm.vue";
import helpMoveForm from "./helpMove/helpMoveForm.vue";

export default {
  components: {
    BodyWithHeader,
    helpMoveForm,
    helpQuestionForm,
    tiketForm,
  },
  data() {
    return {
      loading: false,
      loadingText: "در حال بارگذاری...",
    };
  },
};
</script>