<template>
  <div class="h-100 p-5">
    <vue-element-loading
      :active="formLoading"
      text="درحال دریافت اطلاعات..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CRow align-horizontal="center">
      <CCol md="6">
        <CRow>
          <CCol col="auto" class="main-card">
            <div id="tiketInfoDetail">
              <span>موضوع تیکت:</span>
              <label>{{ tiketInfo.title }}</label>
            </div>
            <hr/>
            <p class="tiketmsg">{{ tiketInfo.description }}</p>
            <div class="text-left small mt-3">{{ tiketInfo.createDate }}</div>
          </CCol>
        </CRow>
        <CRow
          v-for="(item, index) in tiketAnswerList"
          class="my-3"
          :key="index"
          :class="item.adminAnswer ? 'adminAnswer justify-content-end' : 'userAnswer justify-content-start'"
        >
          <CCol
            col="auto"
            class="main-card"
          >
            <h6 v-if="item.adminAnswer">
              پاسخ مدیر
            </h6>
            <hr v-if="item.adminAnswer"/>
            <p>{{ item.description }}</p>
            <a target="_blank" class="y-center-g-5" :href="getSrc(item.file)">
              <font-awesome-icon icon="download"/>
              <span>دانلود فایل پیوست</span>
            </a>
            <div class="text-left small mt-3">
              {{ item.createDate }}
            </div>
          </CCol>
        </CRow>

        <CRow class="w-100 mt-2" v-if="tiketInfo.stateTitle !== 'بسته شده'">
          <hr class="my-4 w-100"/>
          <replay-tiket :tiket-id="tiketId"/>
        </CRow>

        <CAlert class="mt-4 y-center-g-5" color="danger" v-if="tiketInfo.stateTitle === 'بسته شده'">
          <span>وضعیت تیکت : </span>
          <font-awesome-icon icon="lock"/>
          <span>{{ tiketInfo.stateTitle }}</span>
        </CAlert>
      </CCol>
    </CRow>

  </div>
</template>

<script>
import {mapActions} from "vuex";
import {apiUrlRoot} from "../../../constants/config";
import ReplayTiket from "./replayTiket.vue";

export default {
  name: "answerListTiket",
  components: {ReplayTiket},
  props: ["tiketId"],
  data() {
    return {
      formLoading: false,
      tiketInfo: {
        number: "",
        title: "",
        createDate: "",
        description: "",
        stateTitle: "",
      },
      tiketAnswerList: [],
    };
  },
  methods: {
    ...mapActions("tiket", ["GetAnswerByTiketId"]),
    async fetchData() {
      this.formLoading = true;
      let result = await this.GetAnswerByTiketId({ tiketId: this.tiketId });
      this.formLoading = false;
      if (result) {
        this.tiketInfo.number = result.tiketInfo.number;
        this.tiketInfo.title = result.tiketInfo.title;
        this.tiketInfo.createDate = result.tiketInfo.createDate;
        this.tiketInfo.description = result.tiketInfo.description;
        this.tiketInfo.stateTitle = result.tiketInfo.stateTitle;
        this.tiketAnswerList = result.answerList;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات <br /><br />",
          type: "error",
        });
      }
    },
    getSrc(file) {
      return `${apiUrlRoot}/Tiket/${file}`;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>


<style>
@import "~animate.css/animate.css";
.fullscreen-modal {
  width: 90%;
  max-width: 90%;
  height: 95dvh;
  top: 0;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.fullscreen-modal .vm-titlebar {
  flex-shrink: 0;
}
.fullscreen-modal .vm-content {
  padding: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.fullscreen-modal .vm-content .scrollable-content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
}
.fullscreen-modal .fullscreen-modal-footer {
  border-top: 1px solid #e5e5e5;
  padding: 15px;
}
</style>
<style scoped>

#tiketInfoDetail label {
  margin: 0 10px;
  width: 200px;
  text-align: center;
}
.tiketmsg {
  padding: 12px;
  margin-top: 2px;
}
.tiketstate {
  width: 100%;
  height: 25px;
  padding: 3px;
  margin: 0;
}

.adminAnswer button {
  border: none;
  background-color: #36a801;
  color: #fff;
}

.userAnswer button {
  border: none;
  background-color: #36a801;
  color: #fff;
}
</style>
