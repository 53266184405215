<template>
  <div class="h-100">
    <div class="body-wrapper pb-5">
      <div class="light-1" v-if="tiketList.length > 0"></div>
      <div class="light-2" v-if="tiketList.length > 0"></div>
      <div class="col-2 p-2">
        <CButton
          block
          color="primary"
          class="y-center-g-5"
          @click="showModal(0)"
        >
          <font-awesome-icon icon="plus" />
          <span>ثبت تیکت جدید</span>
        </CButton>
      </div>
      <CRow align-horizontal="center" class="gap-20">
        <vue-element-loading
          :active="formLoading"
          text="درحال دریافت اطلاعات..."
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <div v-if="tiketList.length > 0" class="w-100">
          <CRow
            class="shadow-card tiketBox py-3 w-100 h-auto mb-2 align-items-center"
            v-for="(item, index) in tiketList"
            :key="index"
          >
            <CCol md="auto" class="tiketBox_info">
              <label>
                <span> موضوع: </span>
                <span>
                  {{ item.title || "---" }}
                </span>
              </label>
            </CCol>
            <CCol md="auto" class="tiketBox_info">
              <label>
                <span> شماره تیکت: </span>
                <span> #{{ item.number }} </span>
              </label>
            </CCol>
            <CCol col class="tiketBox_info">
              <label style="direction: ltr"
                >در تاریخ : {{ item.createDate }}</label
              >
            </CCol>
            <CCol col="auto">
              <hr class="vertical" />
            </CCol>
            <CCol col="auto" class="tiketBox_btn">
              <CRow class="gap-20">
                <CButton
                  size="sm"
                  color="primary"
                  @click="showModalAnswer(item.id, item.number)"
                >
                  <font-awesome-icon icon="envelope-open-text" />
                  نمایش
                </CButton>
                <CButton
                  size="sm"
                  color="danger"
                  variant="outline"
                  @click="closeTiket(item.id)"
                  v-if="item.state != 4"
                >
                  <font-awesome-icon icon="times" />
                  بستن تیکت
                </CButton>
              </CRow>
            </CCol>
            <CCol col="auto">
              <span
                class="chip"
                :class="
                  item.stateTitle !== 'بسته شده'
                    ? 'bg-success text-white'
                    : 'bg-dark text-white'
                "
              >
                {{ item.stateTitle }}
              </span>
            </CCol>
          </CRow>
        </div>
        <no-data v-else show message="تیکتی ثبت نشده است" />
        <VueModal
          v-model="modalShow"
          :title="modalTitle"
          wrapper-class="animate__animated animate__faster"
          modal-class="drawer"
          in-class="animate__fadeInRight"
          out-class="animate__fadeOutRight"
        >
          <newTiket @tiketInsertState="newTiketState" />
        </VueModal>
        <VueModal
          v-model="answerModalShwo"
          :title="answerModalTitle"
          wrapper-class="animate__animated animate__faster"
          in-class="animate__fadeIn"
          out-class="animate__fadeOut"
          modal-class="fullscreen-modal"
          @before-open="beforeOpen"
          @before-close="beforeClose"
        >
          <div class="scrollable-content">
            <answerListTiket :tiketId="tiketId" />
          </div>
        </VueModal>
        <VueModal
          :title="replayModalTitle"
          v-model="replayModalShow"
          wrapper-class="animate__animated animate__faster"
          :in-class="`animate__fadeIn`"
          :out-class="`animate__fadeOut`"
          bg-class="animate__animated"
        >
          <replayTiket :tiketId="tiketId" @closeModel="closeReplayModel" />
        </VueModal>
      </CRow>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../../constants/config";
import newTiket from "./newTiket.vue";
import answerListTiket from "./answerListTiket.vue";
import replayTiket from "./replayTiket.vue";
import bodyScroll from "body-scroll-freezer";
import NoData from "../../../components/no-data.vue";

export default {
  name: "tiketForm",
  data() {
    return {
      formLoading: false,
      modalShow: false,
      modalTitle: "",
      answerModalShwo: false,
      answerModalTitle: "",
      replayModalTitle: "",
      replayModalShow: false,
      windowHeight: window.innerHeight - 200,
      cardBoxStyle: "",

      tiketList: [],
      tiketId: 0,
    };
  },
  components: {
    NoData,
    newTiket,
    answerListTiket,
    replayTiket,
  },
  methods: {
    ...mapActions("tiket", [
      "GetAllWithUser",
      "CloseTiket",
      "GetAnswerByTiketId",
      "GetById",
    ]),

    async fetchData() {
      this.cardBoxStyle = `height: ${this.windowHeight}px;min-height: ${this.windowHeight}px;max-height: ${this.windowHeight}px;overflow-y: auto;overflow-x: hidden;`;
      this.formLoading = true;
      var result = await this.GetAllWithUser({});
      this.formLoading = false;
      this.tiketList = result;
    },
    showModal(id) {
      if (id == 0) {
        this.modalShow = true;
        this.modalTitle = "ثبت تیکت جدید";
      } else {
        return;
      }
    },
    showModalAnswer(id, number) {
      if (id > 0) {
        this.answerModalShwo = true;
        this.answerModalTitle = `لیست پاسخ های تیکت #${number}`;
        this.tiketId = id;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات <br /><br />",
          type: "error",
        });
      }
    },
    showModalReplay(id, number) {
      if (id > 0) {
        this.tiketId = id;
        this.replayModalTitle = ` پاسخ به تیکت  #${number}`;
        this.replayModalShow = true;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "تیکت مورد نظر بسته شده است <br /><br />",
          type: "info",
        });
        return;
      }
    },
    async newTiketState(state) {
      if (state) {
        this.$notify({
          title: "پیام سیستم",
          text: "ثبت تیکت انجام شد <br /><br />",
          type: "success",
        });
        await this.fetchData();
        this.modalShow = false;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در ثبت تیکت جدید <br /><br />",
          type: "error",
        });
      }
    },
    async closeReplayModel() {
      this.replayModalShow = false;
    },
    async closeTiket(closeTiketId) {
      this.$confirm({
        message: `تیکت مورد نظر بسته شود؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.formLoading = true;
            let result = await this.CloseTiket({ tiketId: closeTiketId });
            this.formLoading = false;
            if (result) {
              if (result.succeeded) {
                await this.fetchData();
                this.$notify({
                  title: "پیام سیستم",
                  text: "تیکت بسته شد. <br /><br />",
                  type: "success",
                });
              } else {
                this.$notify({
                  title: "خطا",
                  text: `${result.message} <br /><br />`,
                  type: "error",
                });
              }
            } else {
              this.$notify({
                title: "خطا",
                text: `خطا رخ داده است <br /><br />`,
                type: "error",
              });
            }
          }
        },
      });
    },
    getSrc(fileName) {
      return `${apiUrlRoot}/Tiket/${fileName}`; //apiUrlRoot + src;
    },

    beforeOpen() {
      bodyScroll.freeze();
    },
    beforeClose() {
      bodyScroll.unfreeze();
    },
  },
  mounted() {
    bodyScroll.init();
    this.fetchData();
  },
  created() {},
};
</script>

<style scoped>
.tiketBox_info label {
  margin: 5px;
  border-bottom: 1px solid #bbb;
}

.tiketBox_msg p {
  width: 100%;
  color: #000;
  background-color: #fff;
  margin: 0 0 5px;
}

.tiketBox_msg label {
  width: 100%;
  height: 25px;
  background-color: #636f83;
  padding: 3px;
  border-right: 3px solid #2f3744;
  color: #fff;
}
</style>
