<template>
  <div class="h-100 position-relative">
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="h-100">
  
      <div class="pb-4 card h-100 velmld-parent">

        <CRow align-vertical="center" align-horizontal="between" class="py-2">
            <CCol col="12">
              <CInput
                type="text"
                name="question"
                v-model="question"
                placeholder="سوال خود را جستجو کنید..."
                class="searchBox mb-0"
              />
            </CCol>
          </CRow>
        <CRow>
          <CCol md="12" class="h-100">
            <div class="questionListBox d-flex gap-10 flex-column">
              <div
                class="questionItem shadow-card d-flex flex-column gap-10 py-3"
                v-for="(item, index) of questionsList"
                :key="index"
              >
                <p class="question">
                  {{ index +1 }} -
                  <span>{{ item.question }}</span>
                </p>
                <p class="answer">
                  {{ item.answer }}
                </p>
              </div>
            </div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

  <script>
import { mapActions } from "vuex";
export default {
  name: "helpQuestion",
  data() {
    return {
      questionsList: null,
      loading: false,
      loadingText: "در حال دریافت اطلاعات...",
      question: null,
    };
  },
  watch: {
    question: function () {
      this.fetchData();
    },
  },
  methods: {
    ...mapActions("helpQuestion", ["GetAll"]),
    async fetchData() {
      this.loading = true;
      let result = await this.GetAll({ answer: null, question: this.question });
      this.loading = false;
      if (result.data) {
        this.questionsList = result.data;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت اطلاعات <br /><br />",
          type: "error",
        });
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped> 
.question {
  font-size: 16px;
}
</style>
